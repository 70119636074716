<script setup lang="ts">
const isLoading = ref(true);
const user = ref(null);

const nuxtApp = useNuxtApp();

const redirectIfUnauthed = async () => {
  const u = await getCurrentUser();

  isLoading.value = false;
  user.value = u;
  if (!u) {
    await navigateTo(nuxtApp.$localePath("/login"));
  } else if (userRequiresVerification(u)) {
    await navigateTo(nuxtApp.$localePath("/verify"));
  }
};

const {
  isLoading: isLoadingUser,
  data,
  suspense,
  isFetched,
} = useCurrentUserSubscriptionQuery();

onServerPrefetch(async () => {
  await redirectIfUnauthed();
  await suspense();
});

watch(isFetched, async () => {
  if (isFetched.value) {
    const locale = data.value.locale;

    if (locale) {
      const baseRoute = nuxtApp.$getRouteBaseName();
      await navigateTo(nuxtApp.$localePath(baseRoute, locale));
    }
  }
});

onMounted(() => {
  window.addEventListener("focus", redirectIfUnauthed);

  redirectIfUnauthed();
});
onUnmounted(() => {
  window.removeEventListener("focus", redirectIfUnauthed);
});
</script>

<template>
  <div v-if="!isLoading && !isLoadingUser && !!user">
    <slot></slot>
  </div>
  <div v-else>
    <AppTopBar />
    <div class="m-auto w-full h-80 flex">
      <font-awesome icon="spinner" size="2x" class="m-auto" spin />
    </div>
  </div>
</template>

<style scoped>
.info-box {
  line-height: 24px;
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  border: 2px solid #11102b;
  .left {
    font-size: 12px;
    width: 50px;
    background-color: #11102b;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
